import React from 'react';
import Banner from '../components/Banner';
import './AboutMePage.css';

const AboutMePage = () => {
  return (
    <div className="about-me-page">
      <div className="banner-wrapper">
        <Banner title="Photography by Bob Taylor" />
      </div>
      <div className="about-content">
        <div className="about-text">
            <h2>Hi, I'm Bob</h2>
            <p>
            I got into photography in high school starting with sports photography. Afterward, I was
            the photo director for the Independent Collegian (RIP) while at the University of Toledo.
            There I covered around 100 events for the newspaper and learned a great deal about the
            fundamentals of photojournalism. I was a Canon shooter from 2011 to 2014 and then
            switched to Sony in 2015. Lately I've gotten into bird photography with my A7RIV and
            now medium format film, which I hope to showcase on this site.
            </p>
            <p>
            By day, I work in the Generative AI space as a software developer. 
            </p>
        </div>
        <div className="about-photo-wrapper">
          <img src="/images/UT_Football.jpg" alt="Bob Taylor" className="about-photo" />
        </div>
      </div>
    </div>
  );
};

export default AboutMePage;