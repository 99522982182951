import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutMePage from './pages/AboutMePage';
import Copyright from './components/Copyright';
import './App.css'; // Ensure this file contains app-wide styles

function App() {
  return (
    <Router>
      <div className="App"> {/* Changed class name to maintain consistency with CSS */}
        <div className="content"> {/* Added content div for flex-grow styling */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about-me" element={<AboutMePage />} />
          </Routes>
        </div>
        <Copyright />
      </div>
    </Router>
  );
}

export default App;