import React, { useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import Banner from '../components/Banner';
import ImageTile from '../components/ImageTile';
import imageList from '../assets/imageList.json'; // Import the generated image list
import './HomePage.css';

const HomePage = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Shuffle the images array
    const shuffledImages = [...imageList].sort(() => Math.random() - 0.5);
    setImages(shuffledImages);
  }, []);

  // Masonry breakpoints for responsiveness
  const breakpointColumnsObj = {
    default: 3, // Default to 3 columns
    1100: 2,    // 2 columns for screens wider than 1100px
    700: 1      // 1 column for screens wider than 700px
  };

  return (
    <div className="home-page">
      <Banner title="Bob Taylor Site" />
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images.map((image, index) => (
          <ImageTile key={index} {...image} />
        ))}
      </Masonry>
    </div>
  );
};

export default HomePage;