import React from 'react';
import './Copyright.css';  // Create this file for custom styles if needed

const Copyright = () => {
  return (
    <div className="copyright">
      &copy; {new Date().getFullYear()} Robert Taylor. All rights reserved.
    </div>
  );
};

export default Copyright;