import React from 'react';
import { Link } from 'react-router-dom';
import './Banner.css'; // Make sure you have a CSS file to style the component

const Banner = () => {
  return (
    <header id="header">
      <div className="site-title">
        <h2>
          <Link to ="/">Photos by Bob Taylor</Link>
        </h2>
      </div>
      <div className="about-link">
        <Link to="/about-me">About Me</Link>
      </div>
    </header>
  );
};

export default Banner;