import React, { useState, useRef, useEffect } from 'react';
import './ImageTile.css';
const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};


const ImageTile = ({ src, alt, linkTo }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const imageRef = useRef(null);
  const clickTimeoutRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // Stop observing after it's visible
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
      clearTimeout(clickTimeoutRef.current);
    };
  }, []);

  const handleClick = (e) => {
    
    if (isTouchDevice() && !isClicked) {

      e.preventDefault(); // Prevent default link action
      setIsClicked(true);

      // Set a timeout to revert the click state after a delay
      clickTimeoutRef.current = setTimeout(() => {
        setIsClicked(false);

      }, 5000); // 5-second delay for re-clicking to actually navigate
    }
  };

  return (
    <a
      href={linkTo}
      className={`image-tile ${isVisible ? 'visible' : ''}`}
      ref={imageRef}
      onClick={handleClick}
    >
      <img src={src} alt={alt} />
      <div className={`image-tooltip ${isClicked ? 'clicked' : ''}`}>{alt}</div>
      {isClicked && isTouchDevice() && <div className="click-again">Tap again for full resolution</div>}
    </a>
  );
};

export default ImageTile;